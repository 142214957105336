/**
 * These colors are mirrored in constants.ts and need to be manually kept in sync if the style guide changes
 */

$blue_dark: #02253c;
$blue_medium: #26436e;
$blue_light: #92a1b7;
$blue_bright: #077ad3;

$grey_light: #eaeaea;
$grey_medium: #939393;
$grey_dark: #393939;
$grey_cadet_light: #e3e7ed;
$grey_cadet_medium: #91a0b6;

$red_light: #ffe9e9;
$red_medium: #e86060;
$red_dark: #c20000;

$orange: #fa7000;
$green: #448e1b;
$white: #ffffff;
$black: #000000;
