// react-daydate picker override
.rdp {
  --rdp-accent-color: #02253c !important;
  --rdp-background-color: #92a1b7 !important;
}

.rdp-day_range_middle {
  opacity: 0.5 !important;
}

/* Hide the calendar icon */
input[type='date']::-webkit-calendar-picker-indicator {
  display: none;
}

.rdp-caption_label {
  padding-top: 4.5px;
  padding-bottom: 4.5px;
}
